import moment from "moment";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API, endpoints } from "../api";
import { ConfirmationNoBtn } from "../components";
import strings, { toastType } from "../utils/strings";
import { errorToString } from "../utils/utils";
import { useAuth } from "./Auth";

export const EnterEventContext = createContext({
  onEventSelect: (_event) => { },
  events: [],
  selectedEvent: null,
  editId: null,

  currency: "₹",
  lateFee: {
    firstDivisionSurcharge: 0,
    additionalDivisionSurcharge: 0,
  },

  setSelectedEvent: (_data) => { },
  withdrawDivision: (_id) => { },

  enrollData: {},
  setEnrollData: (_data) => { },
  enrollEvent: () => { },
  agreeRef: null,

  termsError: {},
  setTermsError: (_val) => { },
  termsChecked: {},
  setTermChecked: (_val) => { },
  setConfirmation: (_val) => { },

  eventVideoFee: (_val) => { },

  isLateFeeAppliedOnEditEvent: false,
});

export const EnterEventProvider = (props) => {
  const [enrollData, setUpdatedEnrollData] = useState({});
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const {
    showToast,
    showGlobalLoader: setLoading,
    userCheck,
    user,
  } = useAuth();

  /** get user data, if user do login on enter event page */
  useEffect(() => {
    if (user && !events?.length) {
      getData()
    }
  }, [user])

  const agreeRef = useRef(null);
  const [termsError, setTermsError] = useState(false);
  const [termsChecked, setTermChecked] = useState(false);

  const [editId, setEditId] = useState();
  const [isLateFeeAppliedOnEditEvent, setLateFeeApplied] = useState(false);

  const params = useParams();
  const location = useLocation();

  useEffect(() => {
    setSelectedEvent(null)
    setUpdatedEnrollData(null);
  }, [location?.pathname])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getAPIUrl = (url) => {
    return props?.enrolId ? `offlineregistration/${url}` : url
  }

  useEffect(() => {
    setLateFeeApplied(false)
    setEditId(params?.editEventId || props?.enrolId);
    if (params?.editEventId || props?.enrolId) {
      getData()
      setLoading(true)
      API.get({
        route: `${getAPIUrl(endpoints.EVENT_ENROL)}?enrolId=${params?.editEventId || props?.enrolId}${props?.userId ? `&userId=${props?.userId}` : ''}`,
      })
        .then((data) => {
          const enrolEditData = data?.result?.find(
            ({ id }) => id == params?.editEventId || props?.enrolId
          );

          enrollData.doWantDvd = (enrollData.doWantDvd || enrolEditData?.doWantDvd)
          updateEventWithType([{ ...enrolEditData?.events, actions: enrolEditData?.actions }]);

          if (!enrollData.division) {
            enrollData.division = [];
          }
          const isLateFeeApplied = enrolEditData?.enrolledDivisions?.find(({ lateFee }) => !!lateFee)
          if (isLateFeeApplied || enrolEditData?.lateFeesApply === 'true') {
            setLateFeeApplied(true)
          }
          enrolEditData?.enrolledDivisions?.map(
            ({ divisionId: eventDivisionId, lateFee, photoFee, entryFee }) => {
              const divisionIndex = enrolEditData?.events?.divisions?.findIndex(
                ({ id }) => id === eventDivisionId
              );

              if (divisionIndex >= 0) {
                const division =
                  enrolEditData?.events?.divisions[divisionIndex];

                enrolEditData.events.divisions[divisionIndex].selected = true;

                const isExist = enrollData?.division?.find(
                  ({ eventDivisionId: id }) => id === eventDivisionId
                );

                if (!isExist) {
                  enrollData.division.push({
                    eventDivisionId,
                    divisionTypeId: division?.divisionTypeId,
                    entryFee,
                    lateFee,
                    photoFee,
                  });
                }
              }
            }
          );

          if (!enrollData?.additionalServiceId) {
            enrollData.additionalServiceId = [];
          }
          enrolEditData?.enrolledAdditionalServices?.map(
            ({ eventAdditionalServiceId: id, serviceFeeYes, serviceFeeNo }) => {
              const val = { id };

              if (serviceFeeYes) {
                val.serviceFeeYes = serviceFeeYes;
              } else if (serviceFeeNo) {
                val.serviceFeeNo = serviceFeeNo;
              }

              const isExist = enrollData?.additionalServiceId?.find(
                ({ id: mapId }) => mapId === id
              );

              if (!isExist) enrollData.additionalServiceId.push(val);
            }
          );


          setSelectedEvent({ ...enrolEditData?.events, actions: enrolEditData?.actions });
          enrollData.eventId = enrolEditData?.events?.id;
          setEnrollData(enrollData, enrolEditData?.events);
        })
        .catch((data) => {
          showToast({
            text: errorToString(data?.response),
            type: toastType.ERROR,
          });
        })
        .finally(() => setLoading(false));
    }
    else getData();
  }, [params?.editEventId]);

  const [currency, setCurrency] = useState("₹");
  const [lateFee, setLateFee] = useState({
    firstDivisionSurcharge: 0,
    additionalDivisionSurcharge: 0,
  });
  const navigate = useNavigate();

  const [confirmation, setConfirmation] = useState({
    title: strings.eventChange,
    subTitle: strings.eventChangeSubTitle,
    yesText: "Yes",
    noText: "No",
    open: false,
  });

  const eventVideoFee = (val, eventData) => {
    const dvdSelectOptions = { 1: "dvdFee", 2: "downloadFee" }; //0 will be No option so price will be 0
    return (val && (selectedEvent || eventData)?.[dvdSelectOptions[val]]) || 0;
  }

  const setEnrollData = (data, eventData) => {
    console.log("selectedEvent => ", selectedEvent)
    console.log("setEnrollData => ", data, eventData)
    const dvdFee = eventVideoFee(data?.["doWantDvd"], eventData);

    const divisionsTotalFee =
      data?.division?.reduce(
        (a, { entryFee = 0, photoFee = 0, lateFee = 0 }) => {
          // return entryFee + photoFee + lateFee + a;
          return photoFee + (lateFee || entryFee) + a;
        },
        0
      ) || 0;

    const additionalServicesFee =
      data?.additionalServiceId?.reduce(
        (a, { serviceFeeYes = 0, serviceFeeNo = 0 }) => {
          return serviceFeeYes + serviceFeeNo + a;
        },
        0
      ) || 0;

    setUpdatedEnrollData({
      ...data,
      amount: dvdFee + additionalServicesFee + divisionsTotalFee,
      divisionsTotalFee,
      eventId: selectedEvent?.id || data?.eventId,

      userId: props?.userId,
      enrollId: props?.enrolId
    });
  };

  const hideConfirmation = () => {
    setConfirmation({ ...confirmation, open: false });
  };


  const onEventSelect = (event) => {
    if (!selectedEvent) {
      return setSelectedEvent(event);
    }

    if (selectedEvent?.id === event?.id) {
      return;
    }
    setConfirmation({
      title: strings.eventChange,
      subTitle: strings.eventChangeSubTitle,
      yesText: "Yes",
      noText: "No",
      open: true,
      noClick: () => hideConfirmation(),
      yesClick: () => {
        const index = events.findIndex(({ id }) => id === selectedEvent?.id);

        const eventOld = events[index];
        eventOld.divisions = eventOld.divisions?.map((ev) => ({ ...ev, selected: false }))
        events[index] = { ...eventOld }
        console.log("index => ", index, eventOld)
        setEvents([...events])

        setSelectedEvent(event);
        hideConfirmation();
        setEnrollData(null);
      },
    });
  };

  const withdrawDivision = (selectedId) => {
    setConfirmation({
      title: strings.eventDivisionWithdraw,
      subTitle: strings.eventChangeSubTitle,
      yesText: "Yes",
      noText: "No",
      open: true,
      noClick: () => hideConfirmation(),
      yesClick: () => {
        hideConfirmation();
        const newData = selectedEvent;
        const findIndex = newData?.divisions?.findIndex(
          ({ id }) => id === selectedId
        );
        newData.divisions[findIndex].selected = undefined;
        setSelectedEvent({ ...newData });

        const index = enrollData?.division?.findIndex(
          ({ eventDivisionId: id }) => id === selectedId
        );
        if (index >= 0) {
          enrollData.division.splice(index, 1);

          /**
           * 
           */
          const indexForFirstEntry = enrollData?.division?.findIndex(
            ({ entryFee }) => entryFee === selectedEvent?.divisionEntryFee
          );
          if (enrollData.division?.length && (enrollData.division?.length === 1 || indexForFirstEntry < 0)) {
            //removed first division
            enrollData.division[0].entryFee =
              selectedEvent?.divisionEntryFee
            enrollData.division[0].lateFee = (enrollData.division[0].lateFee || isLateFeeAppliedOnEditEvent) ? (!isLateFeeAppliedOnEditEvent && editId ? 0 : lateFee?.firstDivisionSurcharge) : 0;
          }
          setEnrollData({ ...enrollData });
        }
      },
    });
  };

  const updateEventWithType = (eves) => {
    setEvents(
      eves
        ?.map((evt) => {
          if (evt.isTfxEvent === "yes") {
            evt.isTransformation = true;
          } else if (evt?.promoter?.[0]?.country == user?.user?.country_id) {
            evt.isDomestic = true;
          } else {
            evt.isInternational = true;
          }

          return evt;
        })
        .filter(
          (evt) => ( //evt?.divisions?.length &&
            (evt?.allowOnlyPro == 'yes') ? (user?.user?.professional == 'yes' || user?.user?.pro_qualified == 'yes') : true
          )
        )
    );
  };

  const getData = () => {
    if (!user) return
    setLoading(true);
    API.get({ route: getAPIUrl(endpoints.EVENT) })
      .then((data) => {
        if (data?.status) {
          updateEventWithType(data?.result);
        } else {
          showToast({
            text: data?.message,
            type: toastType.ERROR,
          });
        }
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setCurrency(`${selectedEvent?.eventCurrency?.sign || ""}`);

    if (
      selectedEvent?.lateEntryFees === "yes" &&
      moment().diff(selectedEvent?.lateFeeStartDate, "days") >= 0
    ) {
      setLateFee({
        firstDivisionSurcharge: selectedEvent?.firstDivisionSurcharge,
        additionalDivisionSurcharge: selectedEvent?.additionalDivisionSurcharge,
      });
    } else {
      setLateFee({
        firstDivisionSurcharge: 0,
        additionalDivisionSurcharge: 0,
      });
    }
  }, [selectedEvent?.id]);

  const enrollEvent = () => {
    userCheck(() => {
      setLoading(true);

      API.post({ route: getAPIUrl(endpoints.EVENT_ENROL), params: enrollData })
        .then((data) => {
          if (props?.updateData) {
            props?.updateData?.()
            showToast({
              text: 'Division/s Updated Successfully!',
            });
          } else {
            navigate(
              // `/enter-event-success/${data?.data?.data}${selectedEvent?.entrySuccessImage ? `?image=${encodeURI(selectedEvent?.entrySuccessImage)}` : ''}`
              `/enter-event-success/${data?.data?.data}/${selectedEvent?.id}`
            );
          }
        })
        .catch((data) => {
          showToast({
            text: errorToString(data?.response),
            type: toastType.ERROR,
          });
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <EnterEventContext.Provider
      value={{
        events,
        onEventSelect,

        selectedEvent,
        setSelectedEvent,

        enrollData,
        setEnrollData,
        enrollEvent,

        withdrawDivision,
        currency,

        agreeRef,
        termsError,
        setTermsError,
        termsChecked,
        setTermChecked,
        setConfirmation,
        eventVideoFee,
        lateFee,
        editId,

        isLateFeeAppliedOnEditEvent,
      }}
    >
      {props.children}
      <ConfirmationNoBtn {...confirmation} />
    </EnterEventContext.Provider>
  );
};

export const useEnterEvent = () => useContext(EnterEventContext);
