import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "../";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";
import strings, { toastType } from "../../utils/strings";

export const PremiumModal = ({ show }) => {
  const {
    hidePremiumModal,
    subscriptionData,
    user,
    updateUser,
    showToast,
    showGlobalLoader,
  } = useAuth();
  const goFree = () => {
    if (show) {
      hidePremiumModal();
      var data = new FormData();
      data.append("IsPremiumMembershipOffered", 1);

      API.post({ route: endpoints.UPDATE_PROFILE, data }).then((data) => {
        hidePremiumModal();
        updateUser({ ...user, user: data?.data });
      });
    }
  };
  const navigate = useNavigate();
  const goPremium = () => {
    hidePremiumModal();

    showGlobalLoader(true);

    var data = new FormData();
    data.append("IsPremiumMembershipOffered", 1);
    API.post({ route: endpoints.UPDATE_PROFILE, data })
      .then((data) => {
        if (
          user?.user?.first_name &&
          user?.user?.date_of_birth &&
          user?.user?.occupation &&
          user?.user?.country_code &&
          user?.user?.country
        ) {
          navigate("/subscription");
        } else {
          navigate("/edit-profile/continue");
          showToast({
            text: strings.completeProfile,
            type: toastType.ERROR,
          });
        }
      })
      .finally(() => showGlobalLoader(false));
  };

  return (
    <>
      {/* {loading ? <Loader /> : null} */}
      <Modal show={show} close={goFree}>
        <div className="premium-modal pt-4">
          <table>
            <thead>
              <tr>
                <th>
                  Get access to all Premium Features...
                </th>
                <th
                  className="text-uppercase text-center bg-gold-gradiant text-white"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  FREE
                </th>
                <th />
                <th
                  className="text-uppercase text-center bg-blue-gradiant text-white"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Premium
                </th>
              </tr>
              <tr>
                <th className="text-red">For a full 365 Days...</th>
                <th
                  className="column-blue text-center bg-gold-gradiant text-white"
                  style={{ borderRadius: "0 0 10px 10px", paddingBottom: 7 }}
                >
                  Complimentary
                </th>
                <th />
                <th
                  className="column-green text-center bg-blue-gradiant text-white"
                  style={{ borderRadius: "0 0 10px 10px", paddingBottom: 7 }}
                >
                  <span>
                    {subscriptionData?.plan?.sign}{" "}
                    {subscriptionData?.plan?.amount}
                  </span>{" "}
                  yr
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Enter any Natural Contest hosted on iCompete</td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Full Dashboard control over your event entry</td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Promote your Fitness Business/Service</td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>
                  Receive <em>The Competitor Encyclopaedia</em> eBook (RRP $119)
                </td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Higher post position on social media wall</td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Access full Contest Event information</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              {/* <tr>
                <td>Full Dashboard control over your event entry</td>
                <td className="text-center">
                  <i className="fa fa-times" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr> */}
              <tr>
                <td>iCompete Social Media Wall</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Share to Facebook &amp; Instagram</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Filter your Wall View from Local to Worldwide</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Fitness resource Locality Guide</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>24/7 Help Desk</td>
                <td className="text-center">
                  <i className="fa fa-check" />
                </td>
                <td colSpan={2} className="text-center">
                  <i className="fa fa-check" />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="joining-text">
                    <i className="feather-info me-1 cursor-pointer hover-effect" style={{ fontSize: '12px' }}></i>
                    <span>
                      Joining to Compete? You'll need a Premium Membership
                    </span>
                  </div>
                </td>
                <td colSpan={3}>
                  <a className="cursor-pointer">
                    <button
                      onClick={goPremium}
                      className="btn btn-success full-width text-uppercase premium-btn"
                    >
                      GO PREMIUM
                    </button>
                  </a>
                </td>
              </tr>
              <tr>
                <td />

                <td colSpan={3} className="text-center">
                  <div className="no-thanks">
                    <span>No thanks, I'll continue as a</span>{" "}
                    <a
                      onClick={goFree}
                      className="text-decoration-underline cursor-pointer"
                    >
                      free member
                    </a>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Modal>
    </>
  );
};
