import { useEffect, useState } from "react";
import { API, endpoints } from "../api";
import { useAuth } from "../context/Auth";
import { errorToString } from "../utils/utils";
import strings, { toastType } from "../utils/strings";
import Content from "../components/content";

const Communication = () => {
  const { showToast, showGlobalLoader } = useAuth();
  const [options, setOptions] = useState({});

  const [loading, setLoading] = useState(true);

  const getData = () => {
    API.get({
      route: endpoints.EMAIL_PREFERENCES,
    })
      .then((data) => {
        setOptions(data?.data);
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => setLoading(false));
  };

  const update = () => {
    showGlobalLoader(true);
    API.post({
      route: endpoints.UPDATE_EMAIL_PREFERENCES,
      data: options,
    })
      .then(() => {
        showToast({
          text: strings.emailPreferences,
        });
      })
      .catch((data) => {
        showToast({
          text: errorToString(data?.response),
          type: toastType.ERROR,
        });
      })
      .finally(() => showGlobalLoader(false));
  };

  useEffect(() => {
    getData();
  }, []);

  return <Content loading={loading} title="Email Preferences"
    icon="fa-solid fa-envelope"
    bottomBtn={
      <button
        className="w200 form-control text-center style2-input text-white fw-600 bg-blue-gradiant border-0 p-0"
        onClick={update}
      >
        Save
      </button>
    }
  >
    {/*
    <p>
      <strong>What You'll Receive</strong>
    </p>
    <div>
      <div class="card bg-transparent-card border-0 d-block">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col email-radio">
            <input
              type="radio"
              name="emails"
              defaultChecked={
                !options.comment &&
                !options.like &&
                !options.mention &&
                !options.message
              }
              onChange={() =>
                setOptions({
                  comment: 0,
                  event: 1,
                  like: 0,
                  mention: 0,
                  message: 0,
                })
              }
            />
          </span>
          <span class="col font-xssss mont-font fw-700">
            Only emails about contests I have entered (Compulsory)
          </span>
        </label>
      </div>
      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col email-radio">
            <input
              type="radio"
              name="emails"
              defaultChecked={
                options.comment ||
                options.like ||
                options.mention ||
                options.message
              }
              onChange={() =>
                setOptions({
                  comment: 1,
                  event: 1,
                  like: 1,
                  mention: 1,
                  message: 1,
                })
              }
            />
          </span>
          <span class="col font-xssss mont-font fw-700">
            Contest Emails and Preferred Emails (Select below)
          </span>
        </label>
      </div>
    </div> */}

    <div className="mt-4">
      {/* <p className="doted-border" /> */}

      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label
          class="toggle toggle-menu-color row m-0 d-flex left-line-toggle"
          style={{ opacity: 0.5 }}
        >
          <span className="col">
            <input
              readOnly
              disabled
              checked
              type="checkbox"
            />
            <span class="toggle-icon dark"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">Contest</span>
            <span className="col-lg-9">
              Email about contests I have entered (Compulsory)
            </span>
          </span>
        </label>
      </div>



      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col">
            <input
              checked={options?.event}
              type="checkbox"
              onChange={(e) =>
                setOptions({ ...options, event: e.target.checked ? 1 : 0 })
              }
            />
            <span class="toggle-icon"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">General</span>
            <span className="col-lg-9">
              General information form ICN
            </span>
          </span>
        </label>
      </div>
      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col">
            <input
              checked={options?.message}
              type="checkbox"
              onChange={(e) =>
                setOptions({ ...options, message: e.target.checked ? 1 : 0 })
              }
            />
            <span class="toggle-icon"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">Chat Message</span>
            <span className="col-lg-9">
              When a message or reply is received
            </span>
          </span>
        </label>
      </div>
      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col">
            <input
              checked={options?.comment}
              type="checkbox"
              onChange={(e) =>
                setOptions({ ...options, comment: e.target.checked ? 1 : 0 })
              }
            />
            <span class="toggle-icon"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">Comments</span>
            <span className="col-lg-9">
              When people write on your post
            </span>
          </span>
        </label>
      </div>

      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col">
            <input
              checked={options?.like}
              type="checkbox"
              onChange={(e) =>
                setOptions({ ...options, like: e.target.checked ? 1 : 0 })
              }
            />
            <span class="toggle-icon"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">Likes</span>
            <span className="col-lg-9">
              When people Like/Love your post or comment
            </span>
          </span>
        </label>
      </div>
      <div class="card bg-transparent-card border-0 d-block mt-2">
        <label class="toggle toggle-menu-color row m-0 d-flex left-line-toggle">
          <span className="col">
            <input
              checked={options?.mention}
              type="checkbox"
              onChange={(e) =>
                setOptions({ ...options, mention: e.target.checked ? 1 : 0 })
              }
            />
            <span class="toggle-icon"></span>
          </span>
          <span class="col row m-0 font-xssss mont-font fw-700">
            <span className="col-lg-3">Tags</span>
            <span className="col-lg-9">
              When people mention you in posts and comments
            </span>
          </span>
        </label>
      </div>
    </div>
  </Content>
};

export default Communication;
