import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API, endpoints } from "../../api";
import { useAuth } from "../../context/Auth";

const EnterEventSuccess = () => {
  const { postId, eventId } = useParams() || {}
  const { loginRedirectUrl } = useAuth()

  const [posterImage, setPosterImage] = useState()
  const [posterFile, setPosterFile] = useState()
  const [entrySuccessImage, setEntrySuccessImage] = useState()

  useEffect(() => {
    API.get({ route: `${endpoints.EVENT}/detail?eventId=${eventId}` })
      .then((data) => {
        if (data?.result?.[0]?.posterImage) {
          setPosterImage(data?.result?.[0]?.posterImage)
        }

        if (data?.result?.[0]?.posterFile) {
          setPosterFile(data?.result?.[0]?.posterFile)
        }

        if (data?.result?.[0]?.entrySuccessImage) {
          setEntrySuccessImage(data?.result?.[0]?.entrySuccessImage)
        }
      })
  }, [])

  return (
    <>
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 ">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900 align-item-center d-flex">
            <i class="fa-sharp fa-solid fa-circle-check font-xxl mr-11 text-share" />
            Event Entry Success
          </h2>
        </div>
      </div>

      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3 membership-payment-success">
        <div class="card-body align-items-center p-0">
          {entrySuccessImage && entrySuccessImage != 'null' ? (
            <div className="text-center mt-3">
              <img
                src={`${entrySuccessImage}`}
              />
            </div>
          ) : null}
          <div className="row mt-3 mb-3 justify-content-center">
            <div className="col-lg-8 d-flex justify-content-center">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${loginRedirectUrl}/post/${postId}`}
                target="_blank"
                className="btn-share">
                <div class="share-logos">
                  <div class="logo-wrapper">
                    <img
                      src="./images/icon-icompete-white.png"
                      alt="iCompete logo"
                    />
                  </div>
                  <div class="logo-wrapper">
                    <img src="./images/icon-fb-white.png" alt="Facebook logo" />
                  </div>
                </div>
                <span className="hidden-sm">Share to Facebook from your iCompete Wall</span>
                <span className="show-sm">Share to Facebook</span>
              </a>
            </div>
          </div>
          <p>
            You now have complete control and access to your entry via iCompete.
            To know When, Where and How to Pay your event fees click PAY in MY
            EVENT. You can edit, or withdraw your entry in EDIT EVENT before you
            pay or entries close. How wonderful! Furthermore, any change you
            make in iCompete will automatically update the entry details the
            Promoter has, so ensure your personal Profile information is always
            current.
          </p>
          <p>
            If available, you can download the event poster and information to
            your device. The Promoter will email you prior to the contest and if
            information changes.
            {/* Please ensure your{" "}
            <Link to="/communication">Communication</Link> Preferences are set
            to receive event updates from iCompete. */}
            If you need further
            information about your contest, please contact the Promoter.
            However, if you require information or troubleshooting using
            iCompete please use <strong>HELP</strong> in your portal.
          </p>

          <div className="d-flex justify-center align-center mt-5 mb-3">
            {posterImage ? <a download={posterImage} target="_blank" href={posterImage} type="submit" class="w200 text-white bg-current border-0 py-1 font-xss rounded-3 d-block text-center">
              <i class="fa-solid fa-download"></i> {" "}
              Event Poster</a> : null}
            {posterFile ? <a download={posterFile} target="_blank" href={posterFile} type="submit" class="w200 text-white bg-current border-0 py-1 font-xss rounded-3 ms-5 d-block text-center">
              <i class="fa-solid fa-download"></i> {" "}
              Event Information</a> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterEventSuccess;
