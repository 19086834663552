import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import strings from "../../utils/strings";

export const Notifications = () => {
  const { notificationCount, notifications, user, userCheck } = useAuth();

  const createMarkup = (msg) => {
    return {
      __html: msg,
    };
  };

  if (!user?.user) {
    return (
      <a
        className="p-2 text-center ms-auto menu-icon cursor-pointer bell-icon-main"
        onClick={() => userCheck()}
      >
        <i className="feather-bell font-xl text-white"></i>
      </a>
    );
  }

  return (
    <>
      <a
        className="p-2 text-center ms-auto menu-icon cursor-pointer"
        id="dropdownMenu3"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {notificationCount ? (
          <span className="dot-count bg-warning"></span>
        ) : (
          ""
        )}
        <i className="feather-bell font-xl text-white"></i>
      </a>


      <div
        className="dropdown-menu dropdown-menu-end p-4 rounded-3 border-0 shadow-lg"
        aria-labelledby="dropdownMenu3"
      >
        <h4 className="fw-700 font-xss mb-4">Notifications</h4>
        {notifications?.length ? (
          <div className="notifications">
            {notifications?.map(({ id, data, created_at }) => (
              <Link
                key={`notification-${id}`}
                to={`/post/${data?.custom_data?.feed_id}`}
                className="card bg-transparent-card w-100 border-0 ps-5 mb-3 notification-single row"
              >
                <div className="col-lg-8 d-flex align-items-center">
                  <img
                    src={data?.custom_data?.img}
                    alt="user"
                    className="w40 position-absolute left-0 rounded-xl"
                  />
                  <h5
                    dangerouslySetInnerHTML={createMarkup(
                      data?.custom_data?.msg
                    )}
                    className="font-xssss text-grey-900 mb-1 mt-0 fw-700 d-block"
                  ></h5>
                </div>
                <div className="col-lg-3">
                  <span className="time font-xsssss fw-600 float-right mt-1">
                    {moment(created_at).fromNow()}
                  </span>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p>{strings.noNotifications}</p>
        )}
      </div>
    </>
  );
};
