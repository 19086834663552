import React, { createContext, useContext, useEffect, useState } from "react";
import {
  ASYNC_STORAGE_KEYS,
  clearData,
  retrieveData,
  storeData,
} from "../utils/storage";
import { API, endpoints } from "../api";
import { useLocation, useParams } from "react-router-dom";

export const AuthContext = createContext({
  loading: true,
  user: "",
  showLoginModal: false,
  showPremiumModal: false,
  subscriptionData: null,
  setUser: () => { },
  updateUser: () => { },
  removeUser: () => { },
  toggleLoginModal: () => { },
  setLoginModal: (_val) => { },
  userCheck: (_fun) => { },
  togglePremiumModal: () => { },
  hidePremiumModal: () => { },
  checkSubscription: () => { },

  showLocationModal: false,
  setLocationModal: () => { },
  searching: false,
  setSearching: () => { },
  loginRedirectUrl: "",
  hideLoginModal: () => { },

  globalLoader: false,
  showGlobalLoader: () => { },
  toast: "",
  showToast: () => { },
  setToast: () => { },

  notificationCount: 0,

  messagesCount: 0,
  setMessagesCount: (_count) => { },

  notifications: [],
  counts: {},

  loginRedirectUrl: "",

  setNavOpen: () => { },
  isNavOpen: false,
});

export const AuthProvider = (props) => {
  const loginRedirectUrl = `${window.location.protocol}//${window.location.host}`;
  const [user, setAppUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [showLoginModal, setLoginModal] = useState(false);
  const toggleLoginModal = () => setLoginModal(!showLoginModal);
  const hideLoginModal = () => setLoginModal(false);

  const [showPremiumModal, setPremiumModal] = useState(false);
  const togglePremiumModal = () => setPremiumModal(!showPremiumModal);
  const hidePremiumModal = () => setPremiumModal(false);
  const [subscriptionData, setSubscriptionData] = useState(null);

  const [showLocationModal, setLocationModal] = useState(false);

  const [notificationCount, setNotificationCount] = useState(0);
  const [messagesCount, setMessagesCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [counts, setCounts] = useState();

  const [globalLoader, showGlobalLoader] = useState(false);
  const [toast, setToast] = useState([]);

  const [isNavOpen, setNavOpen] = useState(false);

  const showToast = ({ text = "", type = "success" }) => {
    setToast([
      {
        text,
        type,
        time: new Date().getTime(),
      },
      ...toast,
    ]);
  };

  const location = useLocation();
  const params = useParams();

  /** Social login flow (Login with token) */
  useEffect(() => {
    const pathName = location.pathname;
    const tokenPrefix = `${loginRedirectUrl}/?token=`;
    if (
      !user &&
      pathName === "/" &&
      window.location.href.includes(tokenPrefix)
    ) {
      const token = window.location.href.split(tokenPrefix)[1]?.split('&email=')[0];
      setLoading(true);
      API.get({ route: `${endpoints.PROFILE}`, token })
        .then((data) => {
          localStorage.clear();
          const newUser = {
            status: true,
            access_token: token,
            token_type: "bearer",
            user: data?.data,
          };
          updateUser(newUser);

          window.history.pushState(null, "", loginRedirectUrl);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location.pathname, params, user]);
  const setUser = (user) => {
    setLoading(false);
    setAppUser(user);
    setLoginModal(false);
  };

  useEffect(() => {
    getHeaderCounts();
    if (user?.user) {
      getNotifications();
      checkSubscription();
    }
  }, [user]);

  useEffect(() => {
    if (user?.user) {
      getUserData();
    }
  }, []);

  const getUserData = () => {
    API.get({ route: endpoints.PROFILE }).then((data) => {
      updateUser({ ...user, user: data?.data });
    });
  };

  const getNotifications = () => {
    API.get({ route: endpoints.NOTIFICATIONS })
      .then((data) => {
        setNotifications(data?.data?.data);
      })
      .catch((err) => { });
  };

  const getHeaderCounts = () => {
    API.get({ route: endpoints.COUNTRY_MEMBER_COUNT })
      .then((data) => {
        setCounts(data?.data);
        setNotificationCount(data?.data?.notificationCount);
        setMessagesCount(data?.data?.unseenMessageCount);
      })
      .catch((err) => { });
  };

  useEffect(() => {
    if (searching) document.body.classList.add("showing-search");
    else document.body.classList.remove("showing-search");
  }, [searching]);

  const checkSubscription = () => {
    return new Promise((resolve, reject) => {
      if (user)
        API.get({ route: endpoints.SUBSCRIPTION })
          .then((data) => {
            setSubscriptionData(data.data);
            resolve(data.data);
            if (
              data?.data &&
              !data?.data?.isSubscribed &&
              user?.user?.IsPremiumMembershipOffered == 0
            ) {
              setPremiumModal(true);
            }
          })
          .catch(() => resolve());
      //  .catch(() => reject());
      else resolve();
    });
  };

  const updateUser = (user) => {
    storeData(ASYNC_STORAGE_KEYS.LOGIN_DATA, user, true);
    setAppUser(user);
    setLoginModal(false);
    if (
      user?.user?.id &&
      !user?.user?.postcode &&
      !user?.user?.postal_address1
    ) {
      setLocationModal(true);
    }
  };

  const removeUser = () => {
    const email = localStorage.getItem("email")
    const password = localStorage.getItem("password")
    const remember = localStorage.getItem("remember")
    localStorage.clear();
    console.log({
      email,
      password,
      remember
    })

    if (email)
      localStorage.setItem("email", email);

    if (password)
      localStorage.setItem("password", password);

    if (remember)
      localStorage.setItem("remember", remember);

    setAppUser(null);
    window.location.href = `${window.location.protocol}//${window.location.host}`;
  };

  const getUserOnInit = async () => {
    setUser(await retrieveData(ASYNC_STORAGE_KEYS.LOGIN_DATA, true));
  };

  const userCheck = (fun, errFun) => {
    if (user) {
      fun();
    } else {
      toggleLoginModal();
      errFun?.();
    }
  };

  useEffect(() => {
    getUserOnInit();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        setUser,
        updateUser,
        removeUser,
        showLoginModal,
        setLoginModal,
        toggleLoginModal,
        userCheck,
        showPremiumModal,
        togglePremiumModal,

        subscriptionData,
        checkSubscription,

        showLocationModal,
        setLocationModal,

        searching,
        setSearching,

        loginRedirectUrl,
        hideLoginModal,
        hidePremiumModal,

        globalLoader,
        showGlobalLoader,
        toast,
        showToast,
        setToast,

        notificationCount,

        messagesCount,
        setMessagesCount,

        notifications,
        counts,

        setNavOpen,
        isNavOpen,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
