import React, { useMemo, useState } from "react";
import { Error, Loader } from "../../";
import { API, endpoints } from "../../../api";
import { errorToString } from "../../../utils/utils";
import { useFeed } from "../../../context/Feed";
import { useAuth } from "../../../context/Auth";
import strings from "../../../utils/strings";
import debounce from "lodash/debounce";
import { Mention, MentionsInput } from "react-mentions";

export const AddComment = ({ id }) => {
  const [content, updateContent] = useState("");
  const [error, setError] = useState("");

  const { updatePostData } = useFeed();
  const { userCheck, showToast, showGlobalLoader: setLoading } = useAuth();

  const divId = useMemo(() => (id = `add_comment_${id}`), [id]);

  const addComment = (event) => {
    setError("");
    event.preventDefault();

    userCheck(() => {
      if (content) {
        setLoading(true);
        var data = new FormData();
        data.append("comment", content);
        data.append("feed_id", id);

        API.post({ route: endpoints.COMMENT, data })
          .then((data) => {
            if (data?.data) {
              showToast({ text: strings.newComment });
              updatePostData({ ...data.data, feed_id: id });
              updateContent("");
              document.getElementById(divId).reset();
              console.log("content new data => ", data);
            }
          })
          .catch((data) => {
            setError(errorToString(data?.response));
          })
          .finally(() => setLoading(false));
      } else {
        setError("Please enter comment text to add a comment");
      }
    });
  };


  const getSearchData = async (str, callback) => {
    if (!str) {
      callback([])
    }
    try {
      const users = await API.get({ route: `${endpoints.SEARCH}?query=${str}` })
      callback(users?.data?.map(({ name, id }) => ({ id, display: name })))
    } catch (e) {
      callback([])
    }
  };
  const delayedHandleChange = debounce(getSearchData, 50);



  return (
    <>
      <div style={{ maxWidth: "80%", marginLeft: "10%" }}>
        <Error error={error} clear={() => setError("")} />
      </div>
      <form
        onSubmit={addComment}
        id={divId}
        className="card w-100 border-0 mt-4 bg-none"
      >
        <div className="card-body p-0  position-relative comment-main ">

          <MentionsInput value={content}
            // className="w-100"
            style={{ width: '100%' }}
            onChange={(event) => {
              console.log("event.target.value =>", event.target.value)
              updateContent(event.target.value)
            }}
            classNames={{
              m__input: "h75 bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-black-500 fw-500 border-light-md theme-dark-bg my-textarea position-relative notranslate",
            }}

            placeholder="Write your comment..."
            customSuggestionsContainer={(props) => <div className="mention-suggestions rounded-xxl p-2 ps-5 font-xssss text-grey-900">{props}</div>}
          >
            <Mention
              markup="@[__display__](user:__id__)"
              trigger="@"
              data={delayedHandleChange}
              renderSuggestion={(
                suggestion,
                search,
                highlightedDisplay,
                index,
                focused
              ) => (
                <div className={`font-xssss text-grey-900 fw-700 render-suggestion-text  ${focused ? 'focused' : ''}`}>
                  {highlightedDisplay}
                </div>
              )}
            />
          </MentionsInput>

          {/* <textarea
            style={{ visibility: 'hidden' }}
            // value={content}
            // onChange={(event) => updateContent(event.target.value)}
            className="h75 bor-0 w-100 rounded-xxl p-2 ps-3 font-xssss text-black-500 fw-500 border-light-md theme-dark-bg my-textarea"
            cols={30}
            rows={10}
            placeholder="Write your comment..."
          /> */}

          <input
            type="submit"
            style={{ maxHeight: 35 }}
            className="ms-auto border-0 p-2 lh-20 w100 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl add-comment-btn"
            value={"Post"}
            Post
          />
        </div>
        {/* <div className="card-body d-flex p-0 mt-0">
          <input
            type="submit"
            style={{ maxHeight: 35 }}
            className="ms-auto border-0 p-2 lh-20 w150 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl"
            value={"Add Comment"}
            Post
          />
        </div> */}
      </form>
    </>
  );
};
